//本地导入
import { ObjectEx } from "@/utils";
import request, { getAppBaseApi, getFileHeaders, getHeaders } from "../request";
import axios from "axios";

//获取排行榜列表
export const getRankList = () =>
  request({
    method: "post",
    url: "/adm/system/rank/list",
  });

//获取排行榜列表
export const setRank = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/rank/set",
  });

//默认消息列表
export const defaultMsgList = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/default/msg/list",
  });

//删除默认消息
export const removeDefaultMsg = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/default/msg/remove",
  });

//保存默认消息
export const saveDefaultMsg = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/default/msg/save",
  });

//融云推送模板-类型
export const ryPushTplType = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/type",
  });

//融云推送模板-保存
export const ryPushTplSave = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/save",
  });

//融云推送模板-移除
export const ryPushTplRemove = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/remove",
  });

//融云推送模板-列表
export const ryPushTplList = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/ry/push/tpl/list",
  });

//退出推荐-列表
export const quitCommendList = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/quit/commend/list",
  });

//退出推荐-删除
export const removeQuitCommend = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/quit/commend/remove",
  });

//退出推荐-保存
export const saveQuitCommend = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/quit/commend/save",
  });

//开机图片-列表
export const bootImageList = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/boot/image/list",
  });

//开机图片-删除
export const removeBootImage = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/boot/image/remove",
  });

//开机图片-保存
export const saveBootImage = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/boot/image/save",
  });

//上传视频
export const updataVideo = (data: any) =>
  axios({
    data: data,
    method: "post",
    headers: getFileHeaders(),
    url: `${getAppBaseApi()}/adm/file/upload`,
  });

//系统分类-保存
export const saveCategory = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/category/save",
  });

//系统分类-删除
export const deleteCategory = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/category/remove",
  });

//系统分类-列表
export const categoryList = (data: any) =>
  request({
    data: data,
    method: "post",
    url: "/adm/system/category/list",
  });
